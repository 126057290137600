import { FocusScope } from '@react-aria/focus';
import { OverlayAria, useOverlay, usePreventScroll } from '@react-aria/overlays';
import cn from 'classnames';
import * as React from 'react';
import { useRef } from 'react';

import { useMountTransition } from '../../hooks/use-mount-transition';
import { useShouldCloseOnInteractOutside } from '../../hooks/use-should-close-on-interact-outside';
import { splitBoxProps } from '../../utils';
import { Box, BoxProps } from '../Box';
import { Overlay } from '../Overlay';

type DrawerDirection = 'right' | 'left' | 'top' | 'bottom';

export type DrawerProps = {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string;
  onClose?: () => void;
  position?: DrawerDirection;
} & BoxProps<'div'>;

export const useDrawerState = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>();

  return {
    isOpen: isOpen,
    setIsOpen: () => setIsOpen(!isOpen),
  };
};

export const Drawer = ({ isOpen, children, className, onClose, position = 'left', ...rest }: DrawerProps) => {
  const ref = useRef();

  const isTransitioning = useMountTransition({ isMounted: isOpen, unmountDelay: 300 });

  const preventClosingFunction = useShouldCloseOnInteractOutside(isOpen);

  // Handle interacting outside the drawer and pressing
  // the Escape key to close the modal.
  const {
    overlayProps: { color: _, ...overlayProps },
    underlayProps: { color: __, ...underlayProps },
  } = useOverlay(
    {
      onClose,
      isOpen,
      isDismissable: true,
      shouldCloseOnInteractOutside: preventClosingFunction,
    },
    ref,
  );

  if (!isTransitioning && !isOpen) {
    return null;
  }

  const { matchedProps: boxProps } = splitBoxProps(rest);

  return (
    <Overlay isOpen={isOpen || isTransitioning}>
      <Box
        aria-hidden={isOpen ? 'false' : 'true'}
        className={cn('sl-drawer-container', {
          open: isOpen,
          in: isTransitioning,
        })}
        {...overlayProps}
      >
        <Underlay isOpen={isOpen} isTransitioning={isTransitioning} {...underlayProps} />
        <DrawerBox position={position} ref={ref} className={className} {...boxProps}>
          {children}
        </DrawerBox>
      </Box>
    </Overlay>
  );
};

type DrawerBoxProps = {
  children: React.ReactNode;
  className?: string;
  position?: DrawerDirection;
} & BoxProps<'div'>;
const DrawerBox = React.forwardRef(function DrawerBox(
  { position, className, children, ...boxProps }: DrawerBoxProps,
  ref: React.RefObject<HTMLDivElement>,
) {
  // Prevent scrolling while the drawer is open
  usePreventScroll();
  const vertical = position === 'left' || position === 'right';

  return (
    <FocusScope contain autoFocus>
      <Box
        role="dialog"
        pos="fixed"
        boxShadow="2xl"
        bg="canvas"
        ref={ref}
        className={cn('sl-drawer', 'sl-duration-300', position, className)}
        {...(vertical ? { h: 'screen' } : { w: 'screen' })}
        {...boxProps}
      >
        {children}
      </Box>
    </FocusScope>
  );
});

type UnderlayProps = { isOpen: boolean; isTransitioning: boolean } & Omit<OverlayAria['underlayProps'], 'color'>;
const Underlay = ({ isOpen, isTransitioning, ...props }: UnderlayProps) => {
  return (
    <Box
      pos="fixed"
      pin
      className={cn('sl-duration-300', isOpen && isTransitioning ? 'sl-opacity-90' : 'sl-opacity-0')}
      bg="canvas-dark"
      {...props}
    />
  );
};
